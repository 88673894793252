<template>
    <div style="width: 370px">
        <file-upload :limit="3" v-model="files"></file-upload>
    </div>
</template>
<script>
export default {
    data() {
        return {
            files: [
                'https://ticket-exchange.oss-cn-hangzhou.aliyuncs.com/image/2020-06-22-17-29-37LyLVuKzG.jpg',
                'https://ticket-exchange.oss-cn-hangzhou.aliyuncs.com/application/2020-06-22-18-25-52jNpAgxrG.7z'
            ]
        };
    }
};
</script>
<style lang="sass" scoped></style>
